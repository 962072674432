import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/grid'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import SuccessIcon from '../../atoms/SuccessIcon'
import { useLoading } from '../../../contexts/loading'
import config from '../../../config'

const stripePromise = loadStripe(config.stripePublishableKey)

const StripePaymentInnerForm = () => {
  const [success, setSuccess] = useState(false)
  const { loading, setLoading } = useLoading()
  const stripe = useStripe()
  const elements = useElements()

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const stripePaymentResult = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      })
      const { paymentIntent, error } = stripePaymentResult
      if (error) {
        throw error
      } else if (paymentIntent.status === 'succeeded') {
        setSuccess(true)
      }
    } catch (err) {
      alert(err.message)
    } finally {
      setLoading(false)
    }
  }, [elements, setLoading, stripe])

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      {success ? (
        <Flex flexDirection="column" alignItems="center">
          <Box width="40%" maxWidth={180} minWidth={120} style={{ aspectRatio: 1 }}>
            <SuccessIcon />
          </Box>
          <Box mt="1rem">
            <Text palette="primary" bold variant="h1" align="center">
              付款成功
            </Text>
          </Box>
          <Box mt="1rem">
            <Text
              palette="primary"
              variant="h3"
              align="center"
              bold
            >
              你已成功增值，<br />現在可以再次使用Crush了！
            </Text>
          </Box>
          <Box mt="2rem">
            <Button to="/">返回對話</Button>
          </Box>
        </Flex>
      ) : (
        <Flex flexDirection="column">
          <PaymentElement
            options={{
              business: { name: 'Crush HK' },
              wallets: { applePay: 'auto', googlePay: 'auto' },
            }}
          />
          <Flex mt="2rem">
            <Button type="submit" disabled={loading} fullWidth>確認</Button>
          </Flex>
        </Flex>
      )}
    </form>
  )
}

const StripePaymentForm = ({ clientSecret, ...props }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret }}
      {...props}
    >
      <StripePaymentInnerForm />
    </Elements>
  )
}

StripePaymentForm.propTypes = {
  clientSecret: PropTypes.string,
}

StripePaymentForm.defaultProps = {
  clientSecret: null,
}

export default StripePaymentForm
