import React from 'react'
import PropTypes from 'prop-types'
import RModal from 'react-modal'
import { IoClose } from 'react-icons/io5'
import { Flex } from '@rebass/grid'
import { useTheme } from 'styled-components'
import Button from '../../atoms/Button'

const Modal = ({ isOpen, onClose, children, showCloseBtn, style, ...props }) => {
  const theme = useTheme()

  return (
    <RModal
      closeTimeoutMS={150}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
      style={{
        overlay: {
          display: 'flex',
          padding: '2rem',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(2px)',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 500,
        },
        content: {
          display: 'flex',
          inset: 'unset',
          border: 'unset',
          flexDirection: 'column',
          alignItems: 'flex-end',
          position: 'relative',
          padding: 0,
          borderRadius: '0.5rem',
          maxHeight: '100%',
          backgroundColor: theme.palette.grayscale[5],
          overflow: 'auto',
          ...style,
        },
      }}
      {...props}
    >
      {showCloseBtn && (
        <Flex paddingTop="0.5rem" paddingRight="0.5rem">
          <Button padding={0} variant="text" palette="grayscale" onClick={onClose}>
            <IoClose size={28} />
          </Button>
        </Flex>
      )}
      <Flex width={1} flexDirection="column" alignItems="center" p="1rem">
        {children}
      </Flex>
    </RModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  showCloseBtn: PropTypes.bool,
  style: PropTypes.object,
}

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  children: null,
  showCloseBtn: false,
  style: {},
}

export default Modal
