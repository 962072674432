import React, { useCallback, useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { size } from 'styled-theme'
import { Box, Flex } from '@rebass/grid'
import Chatroom from '../../organisms/Chatroom'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import Modal from '../../molecules/Modal'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { handleErrorResponse } from '../../../utlils/general'
import { getMyUserState, updateMyUserState } from '../../../api/users'
import { restartChat } from '../../../api/messages'
import { SOCKET_EVENT_MESSAGE_QUOTA_UPDATE, SOCKET_EVENT_USER_STATE_UPDATE } from '../../../socket/socket-io'
import useChatMessages from '../../../hooks/useChatMessages'
import { useLoading } from '../../../contexts/loading'
import { useAuth } from '../../../contexts/auth'
import UserDecisionActionSection from './UserDecisionAction'
import FindNextActionSection from './FindNextAction'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 1rem 3rem;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const StyledContentBox = styled(ContentBox)`
  height: 100%;
  padding: 0.5rem;
`

const EmailVerificationReminder = () => (
  <Flex
    flex={1}
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    marginTop="4rem"
    marginBottom="2rem"
  >
    <Text variant="h2" align="center" palette="error">⚠️ 注意 ⚠️</Text>
    <Flex flexDirection="column" alignItems="center" marginTop="1rem" marginBottom="1rem">
      <Text variant="h3" palette="primary" align="center">請先驗證<b>E-mail</b> ☝️</Text>
      <Text variant="h3" palette="primary" align="center">以繼續與Crush仔對話</Text>
    </Flex>
    <Flex alignItems="center">
      <Button variant="text" padding={0} palette="grayscale" to="/profile">個人頁面</Button>
      <Text style={{ marginLeft: 4, marginRight: 4 }}> ➡️ E-mail</Text>
      <Text> ➡️ 驗證</Text>
    </Flex>
  </Flex>
)

const HomePage = () => {
  const [userState, setUserState] = useState({})
  const [confirmingNewMode, setConfirmingNewMode] = useState(null)
  const { auth, setAuth, setAuthUser } = useAuth()
  const { setLoading } = useLoading()

  const { chat: currentChat, messages, webSocket, sendChatMessage, fetchMessages } = useChatMessages(
    useMemo(() => ({
      bot_reply: 1,
      orderByDesc: 'createdAt',
    }), []),
  )

  const isBotTyping = useMemo(() => {
    if (messages.length === 0) {
      return false
    }
    const lastMessage = messages[messages.length - 1]
    return lastMessage.content === '正在輸入中...'
  }, [messages])

  const fetchUserStateFromApi = useCallback(() => {
    const userStateParams = {}
    return getMyUserState(userStateParams)
      .then(({ data }) => {
        setUserState(data)
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
  }, [setAuth])

  const onChangeUserModeToApi = useCallback((newMode) => {
    if (!currentChat) {
      return Promise.resolve()
    }
    let oriMode = null
    setUserState((oriState) => {
      oriMode = oriState.mode
      return { ...oriState, mode: newMode }
    })
    setLoading(true)
    return updateMyUserState({ mode: newMode, chat_id: currentChat?.chat_id })
      .then(() => fetchMessages())
      .catch((err) => {
        setUserState((oriState) => ({ ...oriState, mode: oriMode }))
        handleErrorResponse(err, setAuth)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [currentChat, fetchMessages, setLoading, setAuth])

  const onResetChatToApi = useCallback(() => {
    if (!currentChat) {
      return Promise.resolve()
    }
    setLoading(true)
    return restartChat(currentChat.chat_id, {})
      .then(() => fetchMessages())
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [currentChat, fetchMessages, setLoading, setAuth])

  useEffect(() => {
    if (currentChat) {
      fetchUserStateFromApi()
    }
  }, [currentChat, fetchUserStateFromApi])

  useEffect(() => {
    if (webSocket && currentChat && auth.user) {
      webSocket.on(SOCKET_EVENT_USER_STATE_UPDATE, (updatedState) => {
        setUserState(updatedState)
      })

      webSocket.on(SOCKET_EVENT_MESSAGE_QUOTA_UPDATE, (messageQuotaUpdate) => {
        const updatedUser = {
          ...auth.user,
          ...messageQuotaUpdate,
        }
        setAuthUser(updatedUser)
      })
    }
  }, [auth.user, currentChat, webSocket, setAuthUser])

  const resetRecommendationString = useMemo(() => {
    if (userState?.mode === 'events') {
      if (userState?.step === 2) {
        return '想轉下要求！？\n咁就要重新再黎過！'
      }
      return null
    }
    if (userState?.step === 3) {
      return '想轉下要求！？\n咁就要重新再黎過！'
    }
    if (userState?.step === 2 && !userState?.suggested_user_id) {
      return '暫時冇人啱，\n不如...試下重新再黎過？'
    }
    return null
  }, [userState])

  const hasAcceptRejectSection = useMemo(() => userState?.step === 2 && !!userState?.suggested_user_id, [userState])
  const hasFindNextSection = useMemo(() => userState?.step === 3 && !!userState?.chosen_user_id, [userState])

  const customSection = useMemo(() => {
    if (!get(auth.user, 'email_verified')) {
      return (
        <EmailVerificationReminder />
      )
    }
    if (isBotTyping) {
      return null
    }
    const actionDisabled = !currentChat || !get(auth.user, 'active') || !get(auth.user, 'email_verified')
    const messageRemaining = get(auth.user, 'message_remaining') || 0
    if (hasAcceptRejectSection) {
      return (
        <UserDecisionActionSection
          onSendMessage={sendChatMessage}
          disabled={actionDisabled || messageRemaining <= 0}
        />
      )
    }
    if (hasFindNextSection) {
      return (
        <FindNextActionSection
          onSendMessage={sendChatMessage}
          disabled={actionDisabled || messageRemaining < 3}
        />
      )
    }
    return null
  }, [auth.user, currentChat, isBotTyping, hasAcceptRejectSection, hasFindNextSection, sendChatMessage])

  return (
    <StyledViewport>
      <StyledContentBox>
        <Chatroom
          flex={1}
          style={{ minHeight: 0 }}
          userId={get(auth.user, 'user_id')}
          mode={userState?.mode}
          onChangeMode={setConfirmingNewMode}
          messages={messages}
          messageQuota={get(auth.user, 'message_quota') || 0}
          messageRemaining={get(auth.user, 'message_remaining') || 0}
          readOnly={!currentChat || isBotTyping || !get(auth.user, 'active') || !get(auth.user, 'email_verified')}
          inputDisabled={hasAcceptRejectSection}
          onMessageSend={sendChatMessage}
          onReset={onResetChatToApi}
          resettable
          customSection={customSection}
          resetRecommended={!!resetRecommendationString}
          resetRecommendationContent={(
            <Text palette="white">{resetRecommendationString}</Text>
          )}
        />
      </StyledContentBox>
      <Modal isOpen={!!confirmingNewMode} onClose={() => setConfirmingNewMode(null)}>
        <Flex flexDirection="column" alignItems="center" p="1rem">
          <Text variant="h3" align="center">{'更改模式會重設對話\n確定繼續？'}</Text>
          <Flex mt="2rem" width={1}>
            <Box flex={1}>
              <Button variant="outline" palette="error" fullWidth onClick={() => setConfirmingNewMode(null)}>取消</Button>
            </Box>
            <Box flex={1} ml="1rem">
              <Button
                onClick={() => {
                  onChangeUserModeToApi(confirmingNewMode)
                  setConfirmingNewMode(null)
                }}
                fullWidth
              >
                確定
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Modal>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default HomePage
