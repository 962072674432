import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { size } from 'styled-theme'
import { Flex } from '@rebass/grid'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { createPayment } from '../../../api/payments'
import { handleErrorResponse } from '../../../utlils/general'
import { useLoading } from '../../../contexts/loading'
import StripePaymentForm from './StripePaymentForm'

const StyledViewport = styled(Viewport)`
 padding: 8rem 2rem 3rem;
 align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const PaymentPage = () => {
  const [stripePaymentClientSecret, setStripePaymentClientSecret] = useState(null)
  const { setLoading } = useLoading()

  const onClickAddValue = useCallback(() => {
    setLoading(true)
    return createPayment({ amount: 1000 })
      .then(({ data }) => {
        const { stripe_client_secret } = data
        setStripePaymentClientSecret(stripe_client_secret)
      })
      .catch((err) => {
        handleErrorResponse(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading])

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="center" mb="2rem">
          <Text palette="primary" bold variant="h1">增值服務</Text>
        </Flex>
        <Flex mt="1rem" flexDirection="column" justifyContent="center">
          {stripePaymentClientSecret ? (
            <Flex p="1rem" justifyContent="center">
              <StripePaymentForm clientSecret={stripePaymentClientSecret} />
            </Flex>
          ) : (
            <Button onClick={onClickAddValue}>
              增值 10 個用量
            </Button>
          )}
        </Flex>
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default PaymentPage
